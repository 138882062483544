import LogoEcoScoreAPlus from './../assets/svg/eco-score-A+.svg'
import LogoEcoScoreA from './../assets/svg/eco-score-A.svg'
import LogoEcoScoreB from './../assets/svg/eco-score-B.svg'
import LogoEcoScoreC from './../assets/svg/eco-score-C.svg'
import LogoEcoScoreD from './../assets/svg/eco-score-D.svg'
import LogoEcoScoreE from './../assets/svg/eco-score-E.svg'
import LogoEcoScoreF from './../assets/svg/eco-score-F.svg'
import LogoEcoScorePlaceholder from './../assets/svg/eco-score-placeholder.svg'

export enum EcoScore {
  A_PLUS = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export function getEcoScoreLogo(ecoScore?: EcoScore) {
  if (ecoScore) {
    switch (ecoScore) {
      case EcoScore.A_PLUS:
        return LogoEcoScoreAPlus
      case EcoScore.A:
        return LogoEcoScoreA
      case EcoScore.B:
        return LogoEcoScoreB
      case EcoScore.C:
        return LogoEcoScoreC
      case EcoScore.D:
        return LogoEcoScoreD
      case EcoScore.E:
        return LogoEcoScoreE
      case EcoScore.F:
        return LogoEcoScoreF
    }
  }
  return LogoEcoScorePlaceholder
}
