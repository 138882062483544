import React, { useState, useEffect, useCallback } from 'react'
import API from '../../infrastructure/api'
import { ButtonPrimary } from '../../components/Button'
import { Link } from 'react-router-dom'
import { Badge } from '../../components/Badge'
import { classNames } from '../../functions/classNames'
import { Product, ProductStatus } from '../../types/Product'
import { useHistory, useParams } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../../components/Notification'
import ProductImagePlaceholder from '../../assets/svg/product-default.svg'
import ProductActionMenu from '../../components/ProductActionMenu'
import PaginationFooter from '../../components/Pagination'
import { useFetchProductsPage } from '../../hooks/useFetchProductsPage'
import { getEcoScoreLogo } from '../../types/EcoScore'
import { SearchBar } from '../../components/Search'
import useQuery from '../../hooks/useQuery'
import Warning from '../../components/Warning'
import { PlusIcon } from '@heroicons/react/solid'
import FilterModal, { Filters } from '../../components/FilterModal'
import BarCodeModal from '../../components/BarCodeModal'
import DeleteProductModal from '../../components/DeleteProductModal'
import { pluralize } from '../../functions/pluralize'
import BulkOperationsMenu from './BulkOperationsMenu'
import BulkUpdateCategoryModal from './BulkUpdateCategoryModal'
import { Checkbox } from '@/components/Checkbox'

export default function ProductsPage() {
  useTitle('Produits')

  const pageSize = 20
  const history = useHistory()
  const params = useParams<{ page?: string | undefined }>()
  const query = useQuery()
  const search = query.get('search')
  const statusFilter = query.get('status') as 'draft' | 'published' | undefined
  const brandFilter = query.get('brand')
  const categoryFilter = query.get('category')
  const ecoScore = query.get('ecoScore')
  const filters: Filters = {}
  if (statusFilter) {
    filters.status = statusFilter
  }
  if (brandFilter) {
    filters.brand = brandFilter
  }
  if (categoryFilter) {
    filters.categorySlug = categoryFilter
  }
  if (ecoScore) {
    filters.ecoScore = ecoScore
  }
  const [activeFilters, setActiveFilters] = useState<Filters>(filters)
  const [page, setPage] = useState<number>(params.page ? Number(params.page) : 1)
  const [tempSearchText, setTempSearchText] = useState<string>(search ?? '')
  const [searchText, setSearchText] = useState<string>(search ?? '')
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
  const [isBulkOperationsModalOpen, setIsBulkOperationsModalOpen] = useState<boolean>(false)

  const { products, pageInfo, loading, updateProduct, forcePageRefresh, error } = useFetchProductsPage(
    page,
    pageSize,
    search,
    filters
  )
  const { setNotification } = useNotification()

  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] = useState<boolean>(false)
  const [productToDelete, setProductToDelete] = useState<Product | undefined>(undefined)

  const pushUpdatedPath = useCallback(() => {
    const url = new URL(`/food/products/${page}`, window.location.origin)
    if (searchText) {
      url.searchParams.append('search', searchText)
    }
    if (activeFilters.status) {
      url.searchParams.append('status', activeFilters.status)
    }
    if (activeFilters.brand) {
      url.searchParams.append('brand', activeFilters.brand)
    }
    if (activeFilters.categorySlug) {
      url.searchParams.append('category', activeFilters.categorySlug)
    }
    if (activeFilters.ecoScore) {
      url.searchParams.append('ecoScore', activeFilters.ecoScore)
    }
    setSelectedProducts([])
    history.push(url.pathname + url.search)
  }, [activeFilters, history, page, searchText])

  useEffect(() => {
    const timeout = setTimeout(() => setSearchText(tempSearchText), 500)
    return () => clearTimeout(timeout)
  }, [tempSearchText, setSearchText])

  useEffect(() => pushUpdatedPath(), [pushUpdatedPath])

  const editProduct = (product: Product) => {
    history.push(`/food/products/edit/${product.ean}`)
  }

  const synchronizeProduct = (product: Product, index: number) => {
    if (!products) return
    const temp = [...products]
    temp[index].status = ProductStatus.Synchronizing
    updateProduct(temp[index], index)
    API.post(`/food/products/${product.ean}/synchronize`)
      .then((response) => updateProduct(response.data, index))
      .catch(() => {
        const temp = [...products]
        temp[index].status = ProductStatus.Draft
        updateProduct(temp[index], index)
        setNotification({
          title: 'Erreur',
          message: `Impossible de synchroniser ${product.name}`,
          type: NotificationType.Error,
        })
      })
  }

  const deleteProduct = (product: Product) => {
    if (!products) return
    setProductToDelete(product)
    setIsDeleteProductModalOpen(true)
  }

  // const getResultColor = (total: number): string => {
  //   if (!total) {
  //     return 'bg-gray-200'
  //   }

  //   if (total >= 75) {
  //     return 'bg-green-600'
  //   }

  //   if (total >= 50) {
  //     return 'bg-green-400'
  //   }

  //   if (total >= 25) {
  //     return 'bg-yellow-500'
  //   }

  //   return 'bg-red-600'
  // }

  if (loading) {
    return <></>
  } else if (error) {
    return <Warning text={"Désolé, quelque chose s'est mal passé."} />
  }

  return (
    <div className="m-6">
      <DeleteProductModal
        product={productToDelete}
        open={isDeleteProductModalOpen}
        endpoint={`/food/products/${productToDelete?.ean}`}
        setOpen={(open) => {
          setIsDeleteProductModalOpen(open)
          if (!open) {
            setProductToDelete(undefined)
          }
        }}
        onSuccess={forcePageRefresh}
      />
      <BulkUpdateCategoryModal
        products={selectedProducts}
        open={isBulkOperationsModalOpen}
        setOpen={setIsBulkOperationsModalOpen}
      />
      <div className="max-w-screen-2xl mx-auto">
        <>
          {selectedProducts.length > 0 ? (
            <div className="flex justify-end items-center mb-4 space-x-4">
              <span className="text-sm text-gray-500">{pluralize(selectedProducts.length, 'sélectionné')}</span>
              <div className="w-px h-6 bg-gray-300" />
              <button
                className="text-sm text-green-500 font-medium hover:text-gray-700"
                onClick={() => setSelectedProducts([])}
              >
                Désélectionner
              </button>
              <BulkOperationsMenu onEditCategoryClick={() => setIsBulkOperationsModalOpen(true)} />
            </div>
          ) : (
            <div className="flex justify-between items-end mb-4 space-x-4">
              <div className="flex-1 max-w-md items-center">
                <SearchBar
                  placeholder="Recherche par EAN ou nom"
                  value={tempSearchText}
                  onChange={(text) => {
                    setPage(1)
                    setTempSearchText(text)
                  }}
                />
              </div>
              <div className="flex items-center space-x-2">
                <FilterModal
                  activeFilters={filters}
                  setActiveFilters={(filters) => {
                    setPage(1)
                    setActiveFilters(filters)
                  }}
                />
                <CreateProductButton />
              </div>
            </div>
          )}
          {products && products.length > 0 ? (
            <div className="inline-block overflow-x-scroll w-full shadow sm:rounded-md bg-white divide-y divide-gray-200">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      <Checkbox
                        id="checkbox_products_all"
                        checked={selectedProducts.length === pageSize}
                        setChecked={(checked) => (checked ? setSelectedProducts(products) : setSelectedProducts([]))}
                      />
                    </th>
                    <th
                      scope="col"
                      className="pl-6 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Statut
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Marque
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Catégorie
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Emballages
                    </th>
                    {/* <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Score santé
                    </th> */}
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Green-score
                    </th>
                    <th scope="col" className="relative px-4 py-3">
                      <span className="sr-only">Menu</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {products.map((product, index) => {
                    const isProductSelected = selectedProducts.find((p) => p.ean === product.ean) !== undefined
                    return (
                      <tr key={product.id} className={isProductSelected ? 'bg-gray-50' : ''}>
                        <td className="pl-6 pr-0 py-4">
                          <Checkbox
                            id={`checkbox_${product.id}`}
                            checked={selectedProducts.find((p) => p.ean === product.ean) !== undefined}
                            setChecked={(checked) =>
                              checked
                                ? setSelectedProducts((currents) => [...currents, product])
                                : setSelectedProducts((currents) => [...currents].filter((p) => p.ean !== product.ean))
                            }
                          />
                        </td>
                        <td className="pl-6 pr-4 py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-none h-20 w-20">
                              <img
                                className={classNames(
                                  'object-scale-down h-20 w-auto',
                                  product.frontPhotoUrl ? 'cursor-pointer' : ''
                                )}
                                src={product.frontPhotoUrl ?? ProductImagePlaceholder}
                                onClick={() => {
                                  if (product.frontPhotoUrl) window.open(product.frontPhotoUrl)
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  editProduct(product)
                                }}
                                className="text-sm font-medium text-gray-900"
                              >
                                {product.name}
                              </a>
                              <BarCodeModal ean={product.ean} label={product.name} />
                            </div>
                          </div>
                        </td>
                        <td className="pl-4 pr-6 py-4 text-sm text-gray-500">
                          {new Date(product.updated).toLocaleDateString()}
                        </td>
                        <td className="px-4 py-4">
                          <StatusBadge status={product.status} />
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-500">{product.brand}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{product.category?.name}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">
                          {product.packagingComponents
                            .map((packagingComponent) => {
                              let name = packagingComponent.component.formatName
                              if (packagingComponent.component.materialName) {
                                name += ` ${packagingComponent.component.materialName}`
                              }
                              return name
                            })
                            .join('; ')}
                        </td>
                        {/* <td className="px-4 py-4 text-sm text-gray-500">
                          <span
                            className={getResultColor(product.healthGrade) + ' inline-block rounded-full h-2 w-2'}
                          ></span>
                          <span className="ml-2">
                            <span className="font-medium text-black">{product.healthGrade}</span>
                            <span className="text-xs text-gray-400">/100</span>
                          </span>
                        </td> */}
                        <td className="pl-4 pr-6 py-4 text-sm text-gray-500">
                          <img className="h-7 w-auto" src={getEcoScoreLogo(product.ecoScore)} />
                        </td>
                        <td className="pl-4 pr-6 py-4">
                          <ProductActionMenu
                            displaySynchronizeAction={product.status === ProductStatus.Draft}
                            onEditClick={() => editProduct(product)}
                            onSynchronizeClick={() => synchronizeProduct(product, index)}
                            onDeleteClick={() => deleteProduct(product)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {pageInfo && <PaginationFooter currentPage={page} pageInfo={pageInfo} onPageClick={setPage} />}
            </div>
          ) : (
            <div className="text-center mt-20">Aucun résultat</div>
          )}
        </>
      </div>
    </div>
  )
}

function CreateProductButton() {
  return (
    <Link to="/food/products/new">
      <ButtonPrimary title="Créer un produit" leftIcon={<PlusIcon className="h-4 w-4" />} />
    </Link>
  )
}

function StatusBadge({ status }: { status: ProductStatus }) {
  switch (status) {
    case ProductStatus.Draft:
      return <Badge title="Brouillon" color="gray" />
    case ProductStatus.Synchronizing:
      return <Badge title="En traitement" color="yellow" />
    case ProductStatus.Synchronized:
      return <Badge title="Publié" color="green" />
  }
}
