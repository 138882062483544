import React, { ReactNode } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Header from '../components/Header'
import PrefilledEcoScoreCalculator from './EcoScoreCalculator/PrefilledEcoScoreCalculator'
import PrefilledHealthGradeCalculator from './FoodHealthGradeCalculator/PrefilledHealthGradeCalculator'
import CosmeticHealthGradeCalculator from './CosmeticHealthGradeCalculator'
import SignInPage from './SignIn'
import { AuthProvider } from '../hooks/useAuth'
import { CookiesProvider } from 'react-cookie'
import { useAuth } from '../hooks/useAuth'
import ProductsPage from './Products'
import ProductFormPage from './Products/Form'
import ProductEditPage from './Products/Edit'
import CosmeticFormPage from './Cosmetic/Form'
import CosmeticProductsPage from './Cosmetic/Products'
import CosmeticProductsEditPage from './Cosmetic/Edit'
import PageNotFound from './NotFound'
import { WithAxios } from './../infrastructure/api'
import ForgotPasswordPage from './ForgotPassword'
import { Notification } from './../components/Notification'
import { NotificationProvider } from './../hooks/useNotification'
import ResetPasswordPage from './ResetPassword'
import AdminUsersList from './Admin'
import OperationsList from './Operations'
import Bugsnag from '@bugsnag/js'

export default function App() {
  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

  const children = (
    <CookiesProvider>
      <AuthProvider>
        <WithAxios>
          <NotificationProvider>
            <Router>
              <Switch>
                <Route path="/signin" component={SignInPage} />
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />
                <Route component={DefaultContainer} />
              </Switch>
              <Notification />
            </Router>
          </NotificationProvider>
        </WithAxios>
      </AuthProvider>
    </CookiesProvider>
  )

  return ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children
}

function DefaultContainer() {
  const { user } = useAuth()
  return (
    <div>
      <Header />
      <Switch>
        <Route
          exact
          path="/"
          render={({ location }) =>
            user ? (
              user.cosmeticsBrands && !user.foodBrands ? (
                <Redirect to={{ pathname: '/cosmetic/products', state: { from: location } }} />
              ) : (
                <Redirect to={{ pathname: '/food/products', state: { from: location } }} />
              )
            ) : (
              <Redirect to={{ pathname: '/green-score-calculator', state: { from: location } }} />
            )
          }
        />
        <Route path="/green-score-calculator" component={PrefilledEcoScoreCalculator} />
        <Route exact path="/health-grade-calculator">
          <Redirect to="/food/health-grade-calculator" />
        </Route>
        <Route path="/food/health-grade-calculator" component={PrefilledHealthGradeCalculator} />
        <Route path="/cosmetic/health-grade-calculator" component={CosmeticHealthGradeCalculator} />
        <PrivateRoute exact path="/food/products">
          <Redirect to="/food/products/1" />
        </PrivateRoute>
        <PrivateRoute path="/food/products/new">
          <ProductFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/food/products/:page">
          <ProductsPage />
        </PrivateRoute>
        <PrivateRoute path="/food/products/edit/:id">
          <ProductEditPage />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/users">
          <Redirect to="/admin/users/1" />
        </PrivateRoute>
        <PrivateRoute path="/admin/users/:page">
          <AdminUsersList />
        </PrivateRoute>
        <PrivateRoute exact path="/operations">
          <Redirect to="operations/1" />
        </PrivateRoute>
        <PrivateRoute path="/operations/:page">
          <OperationsList />
        </PrivateRoute>
        <PrivateRoute path="/cosmetic/products/new">
          <CosmeticFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/cosmetic/products">
          <Redirect to="/cosmetic/products/1" />
        </PrivateRoute>
        <PrivateRoute exact path="/cosmetic/products/:page">
          <CosmeticProductsPage />
        </PrivateRoute>
        <PrivateRoute path="/cosmetic/products/edit/:id">
          <CosmeticProductsEditPage />
        </PrivateRoute>
        <Route component={PageNotFound} />
      </Switch>
    </div>
  )
}

function PrivateRoute({ children, path, exact }: { children: ReactNode; path: string; exact?: boolean }) {
  const { user } = useAuth()
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
